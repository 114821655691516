import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons'
import { ArticleSchule } from "./ArticleSchule";
import Newsletter from "./Newsletter";
import { ArticleAusbildung } from "./ArticleAusbildung"
import { ArticleBiodanza } from "./ArticleBiodanza";
import CookieBanner from "./CookieBanner"

export const HomePage = () => {
    return (
        <div>

            <div className="header">
                {/*<div className="header-title ">*/}
                {/*    Biodanzaschule Freiburg*/}
                {/*</div>*/}
            </div>
            <ArticleBiodanza />
            <div className="quote ">
                <div style={{ padding: "0 50px" }} >
                    <FontAwesomeIcon icon={faQuoteLeft} style={{ opacity: "0.8" }} /> <br />
                    Biodanza ist die Kunst, die das Leben feiert und die Liebe zum Leben weckt.
                    <p style={{ fontSize: "25px" }}>
                        <em>- Rolando Toro</em>
                    </p>
                </div>
            </div>

            <ArticleSchule />

            <ArticleAusbildung />


            <Newsletter />

            {/* <CookieBanner /> */}
        </div>
    )
}