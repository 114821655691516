import React from 'react';
import "../css/newsletter.scss"
import fbIcon from "../imgs/fb.png"
import whatsappIcon from '../imgs/whatsapp.png'
import { BackButton } from "../UI/Buttons";
import "../css/global.scss"


const Kontakt = () => {
    const fbUrl = "https://www.facebook.com/profile.php?id=100095461966010&sfnsn=scwspwa";
    const whatsappNumber = "+491774347677";

    const handleFacebookClick = () => {
        window.open(fbUrl, '_blank');
    };

    const handleWhatsappClick = () => {
        window.open(`https://wa.me/${whatsappNumber}`, '_blank');
    };

    return (
        <>
            {/*    <div className="contact-bg ">*/}
            {/*</div>*/}

            <BackButton path={-1} />

            <div

            // style={{ minHeight: "80vh"}}
            >


                <div className="article-container-new">
                    <div className="article ">


                        <h2>
                            KONTAKT
                        </h2>


                        <div className="w-100 center">
                            <h3 style={{ fontSize: "3vh" }}>Wir freuen uns auf deine Nachricht</h3>
                        </div>

                        <div style={{ fontWeight: "bold", marginTop: "20px" }} className="center">
                            Beate Hoffman
                            <a style={{ textDecoration: "none", color: "#5E5E5E" }} href="mailto:info@biodanzaschule-freiburg.de"> info@biodanzaschule-freiburg.de</a>
                            <a style={{ textDecoration: "none", color: "#5E5E5E" }} href="tel:+491774347677">0177 43 47 677</a>
                        </div>

                        <p className="d-flex flex-row center icons">
                            <img src={fbIcon} alt="Facebook" onClick={handleFacebookClick}
                                style={{ cursor: 'pointer', marginRight: '10px', height: "68px" }} />
                            <img src={whatsappIcon} alt="WhatsApp" onClick={handleWhatsappClick}
                                style={{ cursor: 'pointer', height: "60px" }} />

                        </p>

                    </div>

                </div>
            </div>
        </>
    );
};

export default Kontakt;
